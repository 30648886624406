/* eslint-disable max-len */
import React, { useEffect,  useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Layout from '../components/layout';
import SectionContainer from '../components/SectionContainer';
import { getLocale } from '../utils/locale';
import { SERVER_URL } from '../utils/environment';
import { whichCompany } from '../utils/pageUtils';

const Conditions = props => {
  const [locale, setLocale] = useState('en')
  const [terms, setTerms] = useState({});

  useEffect(() => {
    setLocale(getLocale());
    fetchConditions();
  }, []);

  const fetchConditions = () => {
    const company = whichCompany();
    console.log(company);
    axios
      .get(`${SERVER_URL}/api/v2/terms_and_conditions/current${company && (`?company=${company.path}`)}`)
      .then((resp) => {
        setTerms(resp.data);
      }).catch(() => {
        setTerms({ text: "An error occured" });
      });
  };

  return (
    <Layout location={props.location}>
      <div className="conditions-page" role="main">
        <SectionContainer num={1} container>
          <div>
            {locale === 'fr' ? (
              <>
                <h2>Conditions générales</h2>
                {terms.fr_text}
              </>
            ) : (
              <>
                <h2>Terms & Conditions</h2>
                {terms.text}
              </>
            )}
          </div>
        </SectionContainer>
      </div>
    </Layout>
  );
};

Conditions.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Conditions;
